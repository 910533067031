import { Buffer } from "buffer";

import * as Sentry from "@sentry/react";

import {
  ExploitError,
  InvalidResponse,
  InvalidResponseData,
  InvalidSerial,
  NoResponse,
  NoResponseData,
  NotEnoughData,
  PatchFailed,
  PortLost,
  SigningServerFailed,
  SigningServerUnreachable,
  UnlockFailed,
  UnknownDevice,
  UnsupportedFirmwareVersion,
} from "./Errors";
import Pack from "./Pack";
import { devices } from "./devices";

export default class Exploit {
  constructor() {
    this.otaEndpoint = 'https://api.fpv.tools/ota';

    this.port = null;
    this.connection = null;

    this.seq = 1;
    this.device = null;
    this.config = null;
    this.version = null;
  }

  async closePort() {
    if(this.port) {
      try {
        await this.port.close();
      } catch(e) {
        console.log("Port closed");
      }
    }

    this.port = null;
    this.connection = null;
  }

  async openPort(port) {
    if(port) {
      this.port = port;
      this.connection = await this.port.open({ baudRate: 19200 });
    }
  }

  async sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  logResponse(response) {
    if(response && response.data) {
      console.log(" - Received response:", Buffer.from(response.data).toString("hex"));
    } else {
      console.log(" - Invalid response");
    }
  }

  // Convenience function to check response for validity.
  // Throws if response is invalid
  checkResponse(response, message, checkData = true, minDataLength = 0) {
    if(!response) {
      throw new NoResponse(message);
    }

    if(!response.data && checkData) {
      throw new NoResponseData(message);
    }

    if(minDataLength > 0 && response.data.length < minDataLength) {
      const length = response.data.length;
      throw new NotEnoughData(`${message} (Expected ${minDataLength}, got ${length})`);
    }
  }

  async sendAndReceive(cmdId, message, wait, timeout = 3000, callback) {
    let response = new Buffer.from([]);
    let object = null;
    let writer = null;

    console.log(" - Request: ", Buffer.from(message).toString("hex"));

    /**
     * Devices, especially the Vista seems to go away randomly, Unfortunately
     * the disconnect event is not triggered quickly enough so we might already
     * end up here trying to send a message.
     *
     * In case we can't get a reader and/or writer - the port went away.
     */
    try {
      writer = this.port.writable.getWriter();
      await writer.write(message);
    } catch(e) {
      console.log("Port lost...");
      throw new PortLost();
    }

    try {
      await writer.close();
      writer.releaseLock();
    } catch(e) {
      console.log("Failed closing writer");
    }

    if(wait) {
      let reader = null;
      let receiving = true;

      const timeoutId = setTimeout(async() => {
        if(receiving && reader) {
          receiving = false;

          if(reader) {
            try {
              await reader.cancel();
              reader.releaseLock();
            } catch(e) {
              // Device might have gone away
            }
          }
        }
      }, timeout);

      const cleanupReader = () => {
        clearTimeout(timeoutId);
        if(reader) {
          reader.releaseLock();
        }
      };

      try {
        reader = this.port.readable.getReader();
        while (receiving) {
          let {
            done,
            value,
          } = await reader.read();

          if(value) {
            response = Buffer.concat([response, value]);
            object = callback(response);

            /**
            * Make sure that there actually is the expected amount of data available.
            * Serial read seems to only read 255 Byte in one go, which might not be enough
            */
            if(object && object.data && object.dataLength === object.data.length) {
              if(object.cmdId && object.cmdId == cmdId) {
                done = true;
                console.log(" - Response:", Buffer.from(object.buffer).toString("hex"));
              } else {
                console.log("Command IDs did not match", object.cmdId, cmdId);
              }
            }
          }

          if(done) {
            receiving = false;
            break;
          }
        }

        cleanupReader();
      } catch(e) {
        cleanupReader();
        throw e;
      }
    }

    return object;
  }

  async talk(command, wait, timeout = 5000, careful = true) {
    const pack = new Pack();

    pack.senderType = 10;
    pack.senderId = 1;
    pack.cmdType = 0;
    pack.isNeedAck = (typeof command.isNeedAck === "undefined" ? 2 : command.isNeedAck);
    pack.encryptType = 0;
    pack.receiverType = command.receiverType;
    pack.receiverId = command.receiverId;
    pack.cmdSet = command.cmdSet;
    pack.cmdId = command.cmdId;

    pack.data = command.data;
    if(Buffer.isBuffer(command.data)) {
      pack.data = Uint8Array.from(command.data);
    }

    pack.timeOut = 3000;
    pack.seq = this.seq;
    this.seq += 1;

    pack.pack();
    const packedBuffer = pack.toBuffer();

    const validator = (response) => {
      const unpacker = new Pack();
      let object = null;
      try {
        unpacker.unpack(response, pack);
        object = unpacker.toObject();
      } catch(e) {
        return null;
      }

      return object;
    };

    /**
     * Do not touch this construct.
     *
     * It is needed because otherwise step 4 (maybe others) will do
     * strange things.
     */
    if(careful) {
      await this.closePort();
      await this.sleep(1000);

      const ports = await navigator.serial.getPorts();
      const port = ports[0];
      await this.openPort(port);
      await this.sleep(1000);
    }

    const object = await this.sendAndReceive(pack.cmdId, packedBuffer, wait, timeout, validator);
    return object;
  }

  async identifyDevice() {
    const command = {
      receiverType: 0,
      cmdSet: 0,
      cmdId: 1,
    };

    const response = await this.talk(command, true);
    this.checkResponse(response, "identifyDevice");

    const responseText = String.fromCharCode.apply(null, response.data);
    const device = responseText.match(/(WM150|LT150|GL150|GP150|gl170)/g)[0];

    if(!devices[device]) {
      throw new UnknownDevice(device);
    }

    return device;
  }

  async getVersion() {
    let version = "Unknown";
    const headerLength = 9;
    let chunk = 0x00;
    let chunks = [];

    /**
     * V1 (and airunits) and V2 goggles have different commands to acquire the version
     * we try both if the  first one fails.
    */
    const commands = [
      {
        receiverType: 31,
        cmdSet: 0,
        cmdId: 79,
      },
      {
        receiverType: 28,
        receiverId: 5,
        cmdSet: 0,
        cmdId: 79,
      },
    ];

    let index = 0;
    while(index < commands.length) {
      let command = commands[index];
      index += 1;

      let left = 1;
      let done = false;
      try {
        do {
          if(left === 0) {
            /**
             * If the "packages left" Byte returns 0x00 there is still one package
             * left - this package will also return 0x00 as "packages left", so
             * we can not simply use a check on left == 0 as exit condition.
             */
            done = true;
          }

          command.data = Buffer.from("0100" + chunk.toString(16).padStart(2, "0") + "0000e8030000", "hex");
          const response = await this.talk(command, true, 5000, false);

          left = response.data[6];
          if(left === undefined) {
            throw new Error("Could not acquire version information");
          }

          const data = response.data.slice(headerLength);
          chunks = new Uint8Array([...chunks, ...data]);
          chunk += 1;
        } while(!done);

        let start = 0;
        let foundStart = false;
        const startPpattern = Buffer.from("<?xml", "ascii");
        while(start <= chunks.length - startPpattern.length) {
          if(
            chunks[start + 0] === startPpattern[0] &&
            chunks[start + 1] === startPpattern[1] &&
            chunks[start + 2] === startPpattern[2] &&
            chunks[start + 3] === startPpattern[3] &&
            chunks[start + 4] === startPpattern[4]
          ) {
            foundStart = true;
            break;
          }

          start += 1;
        }

        if(foundStart) {
          const xmlData = chunks.slice(start);
          const xmlString = new TextDecoder("utf-8").decode(xmlData);
          const xmlParser = new DOMParser();
          const xmlDoc = xmlParser.parseFromString(xmlString, "text/xml");
          const firmwareNode = xmlDoc.getElementsByTagName("firmware")[0];
          return firmwareNode.getAttribute("formal");
        }
      } catch(e) {
        console.log(e);
      }
    }

    return version;
  }

  async restart() {
    /**
     * Some devices don't respond to restart requests, so we are not waiting
     * for a responsne and let the wrapping application handle the subsequent
     * disconnect.
     */
    return this.talk(this.config.restart, false);
  }

  makeShellPayload(script) {
    const escapedScript = `;${script}`;
    const buffer = Buffer.from(escapedScript, "utf8");
    const length = Buffer.allocUnsafe(2);
    length.writeUInt16LE(buffer.length);

    return Buffer.concat([
      Buffer.from("746573745f6c65645f666f725f70657263657074696f6e2e73680000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001a0000000b00000088130000", "hex"),
      length,
      buffer,
    ]);
  }

  // Identify device
  async unlockStep1() {
    this.device = await this.identifyDevice();
    this.config = devices[this.device];

    Sentry.captureMessage("Step 1", { extra: { device: this.device } });

    return this.device;
  }

  // OTA - get challenge, get it signed and depeloy
  async unlockStep2() {
    const command = this.config.ota;
    command.data = command.request;
    let response = await this.talk(command, true);

    const minDataLength = 6;
    this.checkResponse(response, "Step 2: get challenge", true, minDataLength);

    //determine challange payload offset
    let offset = 0;
    if(response.data[6] === 0x0e) {
      //old devices are at 6
      offset = 1;
    } else if(response.data[5] === 0x0e) {
      //newer devices are at 5
      offset = 0;
    } else {
      this.logResponse(response);
      throw new ExploitError("Step 2: Could not find challenge offset.");
    }

    const data = response.data;
    const challengeString = Buffer.from(data).toString("hex");

    console.log("Got challenge...");
    Sentry.captureMessage("Step 2: Challenge", { extra: { challengeString } });

    // Manually base64url encode since the browser implementation of Buffer
    // does not yet support base64url encoding.
    let base64Encoded = Buffer.from(data).toString("base64");
    base64Encoded = base64Encoded.replaceAll("=", "");
    base64Encoded = base64Encoded.replaceAll("/", "_");
    base64Encoded = base64Encoded.replaceAll("+", "-");

    const baseUrl = this.otaEndpoint;
    const url = `${baseUrl}/${base64Encoded}`;

    let res = null;
    try {
      res = await fetch(url);
    } catch(e) {
      throw new SigningServerUnreachable();
    }

    if(!res || res.status !== 200) {
      throw new SigningServerFailed();
    }

    const text = await res.text();
    if(text === "nope!" || text === "") {
      const serial = Buffer.from(response.data.slice(offset + 6, offset + 6 + 14)).toString();
      throw new InvalidSerial(`Your serial "${serial}" was not recognized by the server. Contact the fpv.wtf team.`);
    }

    Sentry.captureMessage("Message from signing server", { extra: { length: text.length } });

    command.data = Buffer.from(text, "base64").slice(0, 106);
    const maxRetry = 10;
    let currentRetry = 0;
    let done = false;
    while(!done && currentRetry < maxRetry) {
      try {
        response = await this.talk(command, true);
        Sentry.captureMessage("Step 2: apply", {
          extra: {
            command,
            response,
          },
        });

        this.checkResponse(response, "Step 2: apply", true, 1);
        const returnCode = response.data[0];
        if(returnCode !== 0) {
          this.logResponse(response);
          if(returnCode == 0xE3) {
            throw new UnsupportedFirmwareVersion();
          }

          throw new InvalidResponseData(`Step 2: apply (Expected 0, got ${returnCode})`);
        }

        done = true;
      } catch(e) {
        if(e instanceof UnsupportedFirmwareVersion) {
          throw(e);
        }

        currentRetry += 1;
        await this.sleep(1000);
      }
    }

    if(!done) {
      throw new InvalidResponse(`Step 2: apply aborted after ${currentRetry} retries.`);
    }

    return command.restart;
  }

  // Patch memory
  async unlockStep3() {
    const command = this.config.patch;
    command.data = this.makeShellPayload(`busybox devmem ${command.address} 16 ${command.value}`);

    const response = await this.talk(command, true);
    Sentry.captureMessage("Step 3", {
      extra: {
        command,
        response,
      },
    });

    this.checkResponse(response, "Step 3", true, 1);
    const returnCode = response.data[0];
    if(returnCode !== 0) {
      this.logResponse(response);
      throw new PatchFailed(`Step 3 (Expected 0, got ${returnCode})`);
    }

    return true;
  }

  // Unlock debug mode
  async unlockStep4() {
    const command = this.config.debug;
    command.data = Buffer.from("00000E00AF5C5F2800295F2FAF4849444A490000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000FF000000FEFFFFFF00000000880AEE0E01D4E85374710961DECE84176BF1F91800000000000000000000000000000000000000000000000000000000", "hex");

    try {
      const response = await this.talk(command, true);
      this.checkResponse(response, "Step 4", true, 1);

      const returnCode = response.data[0];
      if(returnCode !== 0) {
        this.logResponse(response);
        throw new UnlockFailed(`Step 4 (Expected 0, got ${returnCode})`);
      }
    } catch(e) {
      if(e instanceof NoResponse) {
        console.log("No response from step 4, device might be rebooting");
      } else if (e instanceof DOMException) {
        console.log("Device went away, that is fine on a Vista");
      } else {
        throw e;
      }
    }

    Sentry.captureMessage("Step 4", { extra: { command } });
  }

  // Install startup routine if necessary
  async unlockStep5() {
    const command = this.config.install;
    if(command.skip) {
      return false;
    }

    let remount = "";
    if(command.engineering) {
      remount = "mount -o bind,ro /blackbox/cmdline /proc/cmdline || true\n";
    }

    command.data = this.makeShellPayload(`cat /proc/cmdline | busybox sed -e 's/state=production/state=engineering/g' -e 's/verity=1/verity=0/g' -e 's/debug=0/debug=1/g' > /blackbox/cmdline
C="busybox devmem ${command.selinuxdisable} 32 0
mount -o rw,remount /system"
eval "$C"
cd /system/bin
T=setup_usb_serial.sh
sed -i '/#margerine/,/#\\/margerine/d' $T
echo "#margerine\n$C\n${remount}#/margerine" >> $T
chgrp shell $T
chcon u:object_r:dji_service_exec:s0 $T
restorecon $T
sync
reboot`);

    const response = await this.talk(command, true);
    Sentry.captureMessage("Step 5", {
      extra: {
        command,
        response,
      },
    });

    this.checkResponse(response, "Step 5", true, 1);
    const returnCode = response.data[0];
    if(returnCode !== 0) {
      this.logResponse(response);
      throw new InvalidResponseData(`Step 5 (Expected 0, got ${returnCode})`);
    }

    return true;
  }
}
