export class ExploitError extends Error {
  constructor(message) {
    super(message);
    this.name = "ExploitError";
  }
}

export class NoResponse extends ExploitError {
  constructor(message) {
    super(message);
    this.name = "NoResponse";
  }
}

export class NoResponseData extends ExploitError {
  constructor(message) {
    super(message);
    this.name = "NoResponseData";
  }
}

export class InvalidResponse extends ExploitError {
  constructor(message) {
    super(message);
    this.name = "InvalidResponse";
  }
}

export class InvalidResponseData extends ExploitError {
  constructor(message) {
    super(message);
    this.name = "InvalidResponseData";
  }
}

export class InvalidSerial extends ExploitError {
  constructor(message) {
    super(message);
    this.name = "InvalidSerial";
  }
}

export class NotEnoughData extends ExploitError {
  constructor(message) {
    super(message);
    this.name = "NotEnoughData";
  }
}

export class PatchFailed extends ExploitError {
  constructor(message) {
    super(message);
    this.name = "PatchFailed";
  }
}

export class PortLost extends ExploitError {
  constructor(message) {
    super(message);
    this.name = "PortLost";
  }
}

export class SigningServerFailed extends ExploitError {
  constructor(message) {
    super(message);
    this.name = "SigningServerFailed";
  }
}

export class SigningServerUnreachable extends ExploitError {
  constructor(message) {
    super(message);
    this.name = "SigningServerUnreachable";
  }
}

export class UnlockFailed extends ExploitError {
  constructor(message) {
    super(message);
    this.name = "UnlockFailed";
  }
}

export class UnknownDevice extends ExploitError {
  constructor(message) {
    super(message);
    this.name = "UnknownDevice";
  }
}

export class UnsupportedFirmwareVersion extends ExploitError {
  constructor(message) {
    super(message);
    this.name = "UnsupportedFirmwareVersion";
  }
}
