import {
  calcCrc8,
  calcCrc16,
} from "./crypto";

export default class Pack {
  constructor() {
    this.sof = 85;

    this.version = 1;

    this.packRepeatTimes = 2;
    this.packTimeOut = 1000;
    this.repeatTimes = 2;
    this.timeOut = 1000;
  }

  getLength() {
    return this.length;
  }

  encodeSequenceNo() {
    const sequence = new Uint8Array(2);
    sequence[0] = (this.seq & 0x00FF);
    sequence[1] = (this.seq & 0xFF00) >> 8;

    return sequence;
  }

  decodeBytes(offset, length) {
    let value = 0;
    if(this.buffer) {
      const start = (offset + length) - 1;
      for(let i = start; i >= offset; i -= 1) {
        value = (value << 8) | (this.buffer[i] & 0xFF);
      }
    }

    return value;
  }

  reCrc() {
    if(this.buffer) {
      const end = this.buffer.length - 2;
      const crcs = calcCrc16(this.buffer, end);
      this.buffer[end + 0] = (crcs & 0x00FF);
      this.buffer[end + 1] = (crcs & 0xFF00) >> 8;
    }
  }

  pack() {
    this.length = 13;
    if(this.data) {
      this.length += this.data.length;
    }

    this.buffer = new Uint8Array(this.length);
    const boxHead = this.buffer;

    boxHead[0] = this.sof;
    boxHead[1] = (this.length & 0xFF);
    boxHead[2] = ((this.length >> 8) & 0x03);
    boxHead[2] = (boxHead[2] | 0x04);

    this.crc8 = calcCrc8(boxHead, 3);
    boxHead[3] = this.crc8;

    boxHead[4] = ((this.senderId << 5) | this.senderType);
    boxHead[5] = ((this.receiverId << 5) | this.receiverType);

    const encodedSequenceNo = this.encodeSequenceNo();
    boxHead[6] = encodedSequenceNo[0];
    boxHead[7] = encodedSequenceNo[1];

    boxHead[8] = ((this.cmdType << 7) | (this.isNeedAck << 5) | this.encryptType);
    boxHead[9] = this.cmdSet;
    boxHead[10] = this.cmdId;

    if(this.data) {
      this.data.forEach((byte, offset) => {
        boxHead[offset + 11] = byte;
      });
    }

    const end = this.length - 2;
    const crcs = calcCrc16(boxHead, end);

    this.crc16 = crcs;

    boxHead[this.length - 2] = (crcs & 0x00FF);
    boxHead[this.length - 1] = (crcs & 0xFF00) >> 8;

    this.buffer = boxHead;
  }

  unpack(buffer, request) {
    if(buffer && buffer.length >= 13) {
      this.buffer = buffer;
      this.sof = buffer[0];

      const decoded = this.decodeBytes(1, 2);
      this.version = decoded >> 10;
      this.length = decoded & 0x3FF;
      this.dataLength = 0;

      this.crc8 = this.buffer[3];
      this.senderId = parseInt(this.buffer[4]) >> 5;
      this.senderType = parseInt(this.buffer[4]) & 31;
      this.receiverId = parseInt(this.buffer[5]) >> 5;
      this.receiverType = parseInt(this.buffer[5]) & 31;
      this.seq = this.decodeBytes(6, 2);
      this.cmdType = parseInt(this.buffer[8]) >> 7;
      this.isNeedAck = (parseInt(this.buffer[8]) >> 5) & 3;
      this.encryptType = parseInt(this.buffer[8]) & 7;
      this.cmdSet = parseInt(this.buffer[9]);
      this.cmdId = parseInt(this.buffer[10]);

      if(request && (this.cmdId !== request.cmdId || this.cmdSet !== request.cmdSet)) {
        if(buffer.length > this.length && buffer[this.length] === 0x55) {
          return this.unpack(buffer.subarray(this.length), request);
        }
        else {
          throw new Error("unexpected response seq and no more packets to try");
        }
      }

      if (this.cmdType === 1) {
        this.ccode = parseInt(this.buffer[11]);
      }

      let headerLength = 11;
      let crcLength = 2;
      let dataLength = this.length - headerLength - crcLength;
      if (dataLength > 0) {
        this.dataLength = dataLength;
        this.data = this.buffer.slice(headerLength, dataLength + headerLength);
      }
      this.crc16 = this.decodeBytes(this.length - 2, 2);
    }
  }

  toBuffer() {
    return this.buffer;
  }

  toObject() {
    return {
      sof: this.sof,
      version: this.version,
      ccode: this.ccode,
      cmdId: this.cmdId,
      cmdSet: this.cmdSet,
      cmdType: this.cmdType,
      crc16: this.crc16,
      crc8: this.crc8,
      dataLength: this.dataLength,
      encryptType: this.encryptType,
      isNeedAck: this.isNeedAck,
      length: this.length,
      receiverId: this.receiverId,
      receiverType: this.receiverType,
      senderId: this.senderId,
      senderType: this.senderType,
      seq: this.seq,
      data: this.data,
      buffer: this.buffer,
    };
  }
}
